/* eslint-disable no-eval */
import React from "react";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";

import GridContainer from "components/Grid/GridContainer.js";

import { bindActionCreators } from "redux";
import { uploadDocument, docStatus } from "../../actions/user";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";
// import {
//   Avatar,
//   Dialog,
//   DialogContent,
//   ListItemAvatar,
// } from "@material-ui/core";
// import Button from "@material-ui/core/Button";
// import MenuItem from "@material-ui/core/MenuItem";
// import Table from "components/Table/Table.js";
// import { IMG_PATH } from "../../config";
// import EditIcon from "@material-ui/icons/Edit";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  imageStyle: {
    width: "100%",
    maxWidth: "156px",
    maxHeight: "156px",
    objectFit: "contain",
    align: "center",
  },
};
function Actions(props) {
  const { person_data } = props.state;
  let images = person_data.image ?? [];
  try {
    images = eval(images);
  } catch (e) {
    images = images.split(" ");
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className="row">
            {images &&
              images.map((image, index) => (
                <img
                  key={index}
                  className="user_profile"
                  alt={index}
                  src={image}
                  style={styles.imageStyle}
                />
              ))}
          </div>
        </GridItem>
      </GridContainer>
      <br></br>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={person_data.name}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            value={person_data.price}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            multiline
            rows={4}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            value={person_data.description}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ uploadDocument, docStatus }, dispatch);
}

export default connect(null, mapDispatchToProps)(Actions);
