import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL, axiosConfig } from "../../config";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Button } from "@material-ui/core";

const ModelOrder = () => {
  const { platformId, brandId } = useParams();
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update,setUpdate]=useState(false)
  const loadApp = () => {
    axios
      .get(`${API_URL}/model/order/${platformId}/${brandId}`, axiosConfig)
      .then(({ data: { models } }) => {
        setModels(models);
      });
  };

  useEffect(() => {
    loadApp();
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    setUpdate(true);
    const updatedModels = Array.from(models);
    const [movedModel] = updatedModels.splice(result.source.index, 1);
    updatedModels.splice(result.destination.index, 0, movedModel);
    const sortedModels = updatedModels.map((model, index) => ({
      ...model,
      order: index + 1
    }));
    setModels(sortedModels);
    const updatedIndexes = updatedModels.map((item) => item.orderId);
    console.log("Updated indexes:", updatedIndexes);
  };
  const handleUpdateOrder = async() => {
    try{
    const response = await axios.put(`${API_URL}/model/update-model`, models, axiosConfig);
    }catch(e){
    }
  };

  return (
    <Card>
      <CardHeader color="primary" className="header_platform">
        <Typography variant="h5">Arrange models</Typography>
      </CardHeader>
      <CardBody>
        <p className="text-center heading-arrange">
          Select two models and click swap button
        </p>{update &&
        <Button variant="contained" color="primary" onClick={handleUpdateOrder}>
              update
            </Button>
}
        {models && models.length > 0 && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <List className="characters" {...provided.droppableProps} ref={provided.innerRef} >
              {models.map(({model,order,orderId}, index) => {
                return (
                  <Draggable key={orderId} draggableId={orderId} index={index}>
                    {(provided) => (
                      <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <span style={{marginRight: "15px"}}>{++index}</span>
                      <ListItemAvatar>
                        <Avatar alt={`Avatar n°${index + 1}`} src={model.image} />
                      </ListItemAvatar>
                      {model.en_name}
                      <ListItemIcon>
                      </ListItemIcon>
                    </ListItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              </List>
          )}
        </Droppable>
      </DragDropContext>
        )}
      </CardBody>
    </Card>
  );
};

export default ModelOrder;