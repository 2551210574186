import React, { Component } from "react";
import { connect } from "react-redux";
import AWS from "aws-sdk";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import GridContainer from "components/Grid/GridContainer.js";
import { IMG_PATH } from "../../config";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import DialogActions from "@material-ui/core/DialogActions";
import { toaster } from "../../helper/Toaster";
import moment from "moment";
import Table from "components/Table/Table.js";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import GridItem from "components/Grid/GridItem.js";
import { awsConfigNew } from "../../config.js";
import MenuItem from "@material-ui/core/MenuItem";

import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import {
  postReferral,
  getReferral,
  updatePromo,
  getStore,
  addEditStore,
  addEditWarranty,
  getWarranty,
} from "../../actions/login";
import {
  addAvailableAreaImage,
  deleteAvailableAreaImage,
  addDiscountCode,
  updateStatus,
  deleteCode,
  updateDiscountCode,
} from "../../actions/device";
import SelectInput from "@material-ui/core/Select/SelectInput";
import { FormControl, InputLabel, Select, makeStyles } from "@material-ui/core";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
};
let s3 = new AWS.S3(awsConfigNew);
let addEditFlag = false;
let warrantyAddEditFlag = false;
const classes = makeStyles(styles);

export class Warranty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warranty: {},
      warranty_edit_flag: false,
      page: 0,
      count: 0,
      rowsPerPage: 5,
      warranty_data: {
        id: "",
        days: {
          value: null,
          error: false,
          message: "",
        },
        amount: {
          value: null,
          error: false,
          message: "",
        },
        event1: {
          value: null,
          error: false,
          message: "",
        },
        event2: {
          value: null,
          error: false,
          message: "",
        },
      },
    };
  }

  // componentWillMount() {
  //   this.props.getWarranty();
  // }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    const { get_warranty, warranty_response } = newProps;

    if (
      warranty_response &&
      warranty_response.status === 200 &&
      warrantyAddEditFlag
    ) {
      toaster("success", warranty_response.message);
      this.setState({ warranty_edit_flag: false });
      warrantyAddEditFlag = false;
    } else if (
      warranty_response &&
      warranty_response.status === 400 &&
      warrantyAddEditFlag
    ) {
      toaster("error", warranty_response.message);
      warrantyAddEditFlag = false;
    }

    if (get_warranty) {

      this.setState({
        warranty_data: {
          days: { value: get_warranty?.warranty?.days, error: false },
          amount: { value: get_warranty?.warranty?.amount, error: false },
          event1: { value: get_warranty?.warranty?.event1, error: false },
          event2: { value: get_warranty?.warranty?.event2, error: false },
        },
      });
    } else {
      toaster("error", get_warranty.message);
    }
  }

  handleChange = (e, name) => {
    if (name === "days") {
      let warranty = { ...this.state.warranty_data }.days;
      warranty.value = e.target.value;
      warranty.error = false;
      this.setState({ days: warranty });
    }
    if (name === "amount") {
      let warranty = { ...this.state.warranty_data }.amount;
      warranty.value = e.target.value;
      warranty.error = false;
      this.setState({ amount: warranty });
    }
    if (name === "event1") {
      let warranty = { ...this.state.warranty_data }.event1;
      warranty.value = e.target.value;
      warranty.error = false;
      this.setState({ event1: warranty });
    }
    if (name === "event2") {
      let warranty = { ...this.state.warranty_data }.event2;
      warranty.value = e.target.value;
      warranty.error = false;
      this.setState({ event2: warranty });

    } else if (name === "edit") {
      this.setState({ warranty_edit_flag: true });

    } else if (name === "cancel") {
      let val = this.state.warranty;
      this.setState({
        warranty_edit_flag: false,
        days: val?.days?.value,
        amount: val?.amount?.value,
        event1: val?.event1?.value,
        event2: val?.event2?.value,
        id: val.id,
      });
    }

    if (e === "edit") {
      let st = { ...this.state };
      st.store_table = "edit";
      st.warranty_data.id = name.id;
      st.warranty_data.days.value = name.days;
      st.warranty_data.amount.value = name.amount;
      st.warranty_data.event1.value = name.event1;
      st.warranty_data.event2.value = name.event2;

      this.setState(st);
    }
  };

  submitWarranty = () => {
    let state = { ...this.state.warranty_data };
    let days = state.days;
    let amount = state.amount;
    let event1 = state.event1;
    let event2 = state.event2;
    let id = state.id;

    let invalidForm = false;

    if (!days.value) {
      days.error = true;
      days.message = "Please enter no. of days";
      invalidForm = true;
    }
    if (!amount.value) {
      amount.error = true;
      amount.message = "Please enter amount";
      invalidForm = true;
    }
    if (!event1.value) {
      event1.error = true;
      event1.message = "Please enter event 1";
      invalidForm = true;
    }
    if (!event2.value) {
      event2.error = true;
      event2.message = "Please enter event 2";
      invalidForm = true;
    }
    if (invalidForm) {
      this.setState({ days, amount, event1, event2 });
      return;
    }
    
    // } else {
    let params = {
      days: days.value,
      amount: amount.value,
      event1: event1.value,
      event2: event2.value,
      id: id,
    };
    this.props.addEditWarranty(params);
    this.props.getWarranty();

    warrantyAddEditFlag = true;
    // }
  };

  render() {
    const { warranty_edit_flag, warranty_data } = this.state;
    return (
      <GridContainer>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Typography variant="h5" className="header_platform">
                Warranty
                {!warranty_edit_flag && (
                  <Tooltip title="Edit" className="icon_boundry">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={(e) => this.handleChange(e, "edit")}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
            </CardHeader>
            <CardBody>
              {/* <span>Referral Amount</span> */}
              <TextField
                margin="dense"
                id="name"
                type="text"
                label="Days"
                value={warranty_data.days.value}
                fullWidth
                error={warranty_data.days.error}
                onChange={(e) => this.handleChange(e, "days")}
                InputProps={{
                  readOnly: warranty_edit_flag ? false : true,
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
              />

              {/* <span>Discount Margin</span> */}
              <TextField
                margin="dense"
                id="name"
                type="text"
                label="Amount"
                value={warranty_data.amount.value}
                fullWidth
                error={warranty_data.amount.error}
                onChange={(e) => this.handleChange(e, "amount")}
                InputProps={{
                  readOnly: warranty_edit_flag ? false : true,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                id="name"
                type="text"
                label="Event 1"
                value={warranty_data.event1.value}
                fullWidth
                error={warranty_data.event1.error}
                onChange={(e) => this.handleChange(e, "event1")}
                InputProps={{
                  readOnly: warranty_edit_flag ? false : true,
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                id="name"
                type="text"
                label="Event 2"
                value={warranty_data.event2.value}
                fullWidth
                error={warranty_data.event2.error}
                onChange={(e) => this.handleChange(e, "event2")}
                InputProps={{
                  readOnly: warranty_edit_flag ? false : true,
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
              {warranty_edit_flag && (
                <DialogActions>
                  <Button
                    onClick={(e) => this.handleChange(e, "cancel")}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={(e) => this.submitWarranty(e)}
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </DialogActions>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    get_warranty: store.login.get_warranty,
    warranty_response: store.login.warranty_response,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWarranty: () => dispatch(getWarranty()),
    addEditWarranty: (params) => dispatch(addEditWarranty(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Warranty);
