import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { connect } from "react-redux";
import { getConversations, getMessages } from "./../../actions/user";
import io from 'socket.io-client';

class Chat extends React.Component {
  constructor(props) {
    super(props);
    const type = this.props.match.params.type;
    if (!["client", "payment", "service"].includes(type)) {
      this.props.history.push("/tech-support");
    }
    this.state = {
      type: this.props.match.params.type,
      technicians: [],
      technician: null,
      messages: [],
      text: {
        value:"",
        invalid: true,
        message: ""
      }
    };
    this.socket = io(process.env.REACT_APP_SOCKET_URL);

  }

  setHeight = () => { }

  pushMessage = (message, type) => {
    let messages = [...this.state.messages];
    messages.push({ message, type })
    this.setState({ messages })
    if(type === "sent"){
      this.setState({ text: {
        value: "",
        invalid: false
      }})
    }
  }

  sendMessage = () => {
    let text = {...this.state.text};
    if(!text.value){
      this.setState({
        text: { 
          invalid: true,
          message : "Please enter something to send!"
        }
      });
    }else{
      this.pushMessage(text.value,"sent");
      this.socket.emit('message_sent',{
        message: text.value,
        sender: "admin",
        receiver: this.state.technician.id,
        booking_id: "ss",
        conversationId: this.state.technician.conversation_id
      });
    }
  }

  componentDidMount() {
    this.socket.on('connect', () => {  
      console.log("connected");
    });
    
    this.socket.emit('init','admin');
    

    this.socket.on('message_received', (data) => {
      if(this.state.technician){
        if(this.state.technician.id == data.sender){
          this.pushMessage(data.message,"received");
        }
      }
    });

    let params = { type: this.props.match.params.type };
    this.props.getConversations(params);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if ("conversations" in newProps) {
      let { conversations } = newProps;

      if (conversations && conversations.status === 200) {
        this.setState({
          technicians: conversations.data,
        });
      }
    }
    if ("messages" in newProps) {
        let { messages } = newProps;
        if (messages && messages.status === true) {
          if(messages.messages && messages.messages.length > 0){
            let msgs = messages.messages;
            let all_msgs = [];
            for(let msg of msgs){
              all_msgs.push({
                message: msg.message,
                type: msg.sender === "admin" ? "sent" : "receive"
              })
            }
            this.setState({
              messages: all_msgs
            })
            this.setHeight();
          }
        }
      }
  }

  selectTech = (tech) => {
    let params = {
      booking_id: "ss",
      userID: "admin",
      lang: "es",
      conversationId: tech.conversation_id
    };
    this.props.getMessages(params);
    this.setState({
      technician: tech,
      messages: []
    });
  };

  handleChange = (e) => {
    this.setState({
      text: {
        value: e.target.value,
        invalid: false
      }
    })
  }

  render() {
    const { type, technicians, technician, messages, text } = this.state;
    
    return (
      <>
        <div className="headings">
          <p>
            Chat with {type}
            <span onClick={() => this.props.history.push("/tech-support")}>
              <SubdirectoryArrowLeftIcon />
            </span>
          </p>
        </div>
        <GridContainer>
          <GridItem sm={4} md={4} lg={4}>
            <div className="chat_box">
              <div className="all_chats">
                {technicians.length === 0 && <p className="margin-top text-center">There are currently no conversations</p>}

                {technicians.map((tech) => (
                  <div
                    key={tech.id}
                    className={
                      (tech === technician ? "active " : "") + "single_chat"
                    }
                  >
                    <p onClick={() => this.selectTech(tech)}>
                      <img
                        src={
                          tech.profile_pic
                            ? process.env.REACT_APP_URL + "/" + tech.profile_pic
                            : "/images/user.jpg"
                        }
                      />
                      {tech.username}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </GridItem>
          <GridItem sm={8} md={8} lg={8}>
            <div className="chat_box" >
              {technician ? (
                <>
                  <div className="chat_header">
                    <p>
                      <img
                        src={
                            technician.profile_pic
                              ? process.env.REACT_APP_URL + "/" + technician.profile_pic
                              : "/images/user.jpg" }
                      />{" "}
                      {technician.username}{" "}
                    </p>
                    <hr />
                  </div>
                  <div className="chat_messages" id="messageDiv" >
                    {messages.length === 0 && <p>There are currently no messages. Send a message to start a conversation</p>}
                    {messages.map((msg,index) => (
                      <div
                      key={index}
                        className={
                          msg.type === "sent"
                            ? "sent_outer text-right"
                            : "receive_outer"
                        }
                      >
                        <p
                          className={
                            msg.type === "sent" ? "sent text-left" : "receive"
                          }
                        >
                          {msg.message}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="chat_footer" >
                    <input 
                    value={text.value} 
                    onChange={this.handleChange} 
                    type="text" 
                    placeholder="Enter message to send" 
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault();
                        this.sendMessage();
                      }
                    }}
                    />
                    <button onClick={this.sendMessage}>Send</button>
                  </div>
                  {text.invalid && <div><p className="error_message">{text.message}</p></div>}
                </>
              ) : (
                <div className="initial">
                  <p>Pick a technician to start a converstation</p>
                </div>
              )}
            </div>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    conversations: store.user.conversations,
    messages: store.user.messages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConversations: (params) => dispatch(getConversations(params)),
    getMessages: (params) => dispatch(getMessages(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
