import moment from "moment";
import AWS from "aws-sdk";
import { awsConfigNew } from "./../../config.js";
let s3 = new AWS.S3(awsConfigNew);

class MyUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {

                let index = file.name.lastIndexOf(".");
                let extension = file.name.substring(index, file.name.length);

                var date_create = moment().format("YYYY-MM-DD-H:m:ss.SS");
                date_create += extension;

                let params = {
                ACL: "public-read",
                ServerSideEncryption: "AES256",
                Body: file,
                Bucket: "blackpatchadmin",
                Key: `media/model/${date_create}`,
                };

                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err.stack);
                    } else {
                        this.loader.uploaded = true;
                        resolve({
                            default: `https://blackpatchadmin.s3.amazonaws.com/media/model/${date_create}`
                        })
                    }
                });
                
            }));
    }
}

// ...

export default function MyCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter( loader );
    };
}