import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "components/Table/Table.js";
import { IMG_PATH } from "../../config";
import EditIcon from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer.js";
import {
  Avatar,
  Dialog,
  DialogContent,
  ListItemAvatar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import { uploadDocument, docStatus } from "../../actions/user";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  imageStyle: {
    width: "100%",
    maxWidth: "156px",
    maxHeight: "156px",
    objectFit: "contain",
    align: "center",
  },
};
function Actions(props) {
  const { warranty_data } = props.state;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="levels"
            label="Levels"
            type="text"
            value={warranty_data?.levels}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="isOngoing"
            label="Ongoing"
            type="text"
            value={warranty_data?.isOngoing === true ? "Yes" : "No"}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="deductible_payment_event_1"
            label="Deductible payment event 1 (%)"
            type="number"
            value={warranty_data?.deductible_payment_event_1}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="deductible_payment_event_2"
            label="Deductible payment event 2 (%)"
            type="number"
            value={warranty_data?.deductible_payment_event_2}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            value={warranty_data?.price}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ uploadDocument, docStatus }, dispatch);
}

export default connect(null, mapDispatchToProps)(Actions);
