import React from "react";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import withTheme from "@material-ui/styles/withTheme";

import { booking_details } from "../../actions/user";
import { connect } from "react-redux";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Typography from "@material-ui/core/Typography";
import CardBody from "components/Card/CardBody.js";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import CancelRequestPopup from "./CancelRequestPopup";
import ReactivateRequestPopup from "./ReactivateRequestPopup";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { BOOKING } from "../../constants/booking";

class ViewBookingDetails extends React.Component {
  constructor(props) {
    super(props);
    let device_fields = {
      modelColor: 0,
      modelName: 1,
      modelType: 0,
      same_device: 0,
      previous_repairs: "",
      turns_on: -1,
      vibration: -1,
      image: -1,
      front_camera: -1,
      battery: -1,
      back_camera: -1,
      touch: -1,
      bluetooth: -1,
      sound: -1,
      signal: -1,
      face_id: -1,
      charge_percent: "",
      wetness: "",
      additional_comments: "",
      signature: "",
      vialable_service: "",
      warranty_sticker: "",
      client_comments: "",
      esthetic: "",
      general_functionality_verification: "",
      additional_testing: "",
      warranty_sticker: "",
    };

    this.state = {
      booking: {
        modelName: "",
        repair_estimate: "",
        dateTime: "",
        address: "",
        status: "",
        issue: [],
      },
      reception: device_fields,
      return_condition: device_fields,
      cancel_dialog: false,
      reactivate_dialog: false,
      restarted: false,
    };
  }
  componentDidMount() {
    this.props.booking_details({ id: this.props.match.params.id });
  }

  getColor = (color) => {
    let col = parseInt(color);
    if (col === 0) {
      return "#E2EFDA";
    }
    if (col === 1) {
      return "#FFE481";
    }
    if (col === 2) {
      return "#C50004";
    }
    return "#fff";
  };

  openCancel = () => {
    this.setState({ cancel_dialog: true });
  };

  openReactivate = () => {
    this.setState({ reactivate_dialog: true });
  };

  closeCancel = () => {
    this.setState({ cancel_dialog: false });
  };

  closeReactivate = () => {
    this.setState({ reactivate_dialog: false });
  };

  backPage = () => {
    this.props.history.push("/bookings");
  };

  handleSuccess = () => {
    this.props.booking_details({ id: this.props.match.params.id });
  };
  handleSuccessReactivate = () => {
    this.setState({ restarted: true });
    // this.props.booking_details({ id: this.props.match.params.id });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const { booking_detail_data } = newProps;
    if (booking_detail_data && booking_detail_data.status === 200) {
      let booking_detail = booking_detail_data.booking;
      booking_detail.issue = JSON.parse(booking_detail.issue);
      if (booking_detail_data.return_condition) {
        this.setState({
          return_condition: booking_detail_data.return_condition,
        });
      }
      if (booking_detail_data.reception) {
        this.setState({ reception: booking_detail_data.reception });
      }
      this.setState({ booking: booking_detail });
    }
  }

  chooseColor(text, color) {
    return (
      <div className="d-flex justify-content-between bd-highlight mb-0">
        <div className="p-2 bd-highlight">{text}</div>
        <div className="p-2 bd-highlight">
          <div
            className="color_box rect"
            style={{ backgroundColor: color }}
          ></div>
        </div>
      </div>
    );
  }

  render() {
    let {
      booking,
      reception,
      return_condition,
      cancel_dialog,
      reactivate_dialog,
      restarted
    } = this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <div>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />

        <CancelRequestPopup
          isOpen={cancel_dialog}
          close={this.closeCancel}
          bookingId={booking.id}
          onSuccess={this.handleSuccess}
        />
        <ReactivateRequestPopup
          isOpen={reactivate_dialog}
          bookingId={booking.id}
          onSuccess={this.handleSuccessReactivate}
          close={this.closeReactivate}
        />
        <Card>
          <CardHeader color="primary">
            <Typography variant="h5" className="header_platform">
              Booking Details
              <Tooltip title="Back" className="icon_boundry">
                <IconButton
                  edge="end"
                  aria-label="back"
                  onClick={(e) => this.backPage()}
                >
                  <SubdirectoryArrowLeftIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Model Name"
                  type="text"
                  value={booking.modelName}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="phone"
                  label="Estimate Price"
                  type="text"
                  value={booking.repair_estimate}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <TextField
                  margin="dense"
                  id="email"
                  label="Finish Date"
                  type="text"
                  value={booking.dateTime}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <TextField
                  margin="dense"
                  id="email"
                  label="Rescheduled Date"
                  type="text"
                  value={booking.tech_delevery ? booking.tech_delevery : ""}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  margin="dense"
                  id="address"
                  label="Pickup Address"
                  type="text"
                  value={booking.address}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="address"
                  label="Status"
                  type="text"
                  value={BOOKING[booking.status] ?? ""}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              {booking.status === "pending" && (
                <GridItem xs={12} sm={12} md={6}>
                  {!restarted && (
                    <div className="m-3">
                      <Button
                        onClick={this.openReactivate}
                        color="primary"
                        variant="contained"
                      >
                        Reactivate
                      </Button>
                      <Button
                        style={{ marginLeft: "10px" }}
                        onClick={this.openCancel}
                        variant="contained"
                      >
                        Cancel Request
                      </Button>
                    </div>
                  )}
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={12}>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">Issues</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={booking.issue}
                    readOnly
                    input={<Input id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value, index) => (
                          <Chip key={index} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  ></Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <div className="my-5">
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="reception_box">
                    <h5 className="text-center">
                      <b>Device Reception</b>
                    </h5>
                    <div className="row">
                      <div className="col-3">
                        <p>Service Validation</p>
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col">
                            <p className="text-center font-bold mb-0">Brand</p>
                            <p className="text-center">
                              <input
                                type="checkbox"
                                className="mr-2"
                                readOnly
                                checked={reception.modelType}
                              />
                              {booking.modelType}
                            </p>
                          </div>
                          <div className="col">
                            <p className="text-center font-bold mb-0">Model</p>
                            <p className="text-center">
                              <input
                                type="checkbox"
                                className="mr-2"
                                readOnly
                                checked={reception.modelName}
                              />
                              {booking.modelName}
                            </p>
                          </div>
                          <div className="col">
                            <p className="text-center font-bold mb-0">Color</p>
                            <p className="text-center">
                              <input
                                type="checkbox"
                                className="mr-2"
                                readOnly
                                checked={reception.modelColor}
                              />
                              {booking.modelColor}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-4 ml-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="sameDeviceChecked"
                            readOnly
                            value="1"
                            checked={reception.same_device}
                          />
                          <p
                            className="form-check-label"
                            htmlFor="sameDeviceChecked"
                          >
                            <strong>Is the device same ?</strong>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3">
                        <p>Previous repairs</p>
                      </div>
                      <div className="col-8 offset-1">
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          placeholder="Some repair"
                          defaultValue={reception.previous_repairs}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {this.chooseColor(
                          "Turns On",
                          this.getColor(reception.turns_on)
                        )}
                        {this.chooseColor(
                          "Image",
                          this.getColor(reception.image)
                        )}
                        {this.chooseColor(
                          "Battery",
                          this.getColor(reception.battery)
                        )}
                        {this.chooseColor(
                          "Touch",
                          this.getColor(reception.touch)
                        )}
                        {this.chooseColor(
                          "Sound",
                          this.getColor(reception.sound)
                        )}
                        {this.chooseColor(
                          "Signal",
                          this.getColor(reception.signal)
                        )}
                      </div>
                      <div className="col-6">
                        {this.chooseColor(
                          "Vibration",
                          this.getColor(reception.vibration)
                        )}
                        {this.chooseColor(
                          "Front Camera",
                          this.getColor(reception.front_camera)
                        )}
                        {this.chooseColor(
                          "Back Camera",
                          this.getColor(reception.back_camera)
                        )}
                        {this.chooseColor(
                          "Bluetooth",
                          this.getColor(reception.bluetooth)
                        )}
                        {this.chooseColor(
                          "WiFi",
                          this.getColor(reception.wifi)
                        )}
                        {this.chooseColor(
                          "Face/Touch ID",
                          this.getColor(reception.face_id)
                        )}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-3">
                        <p>% Charge</p>
                      </div>
                      <div className="col-8 offset-1">
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          placeholder="Some repair"
                          defaultValue={reception.charge_percent}
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-3">
                        <p>Wetness</p>
                      </div>
                      <div className="col-8 offset-1">
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          placeholder="Some repair"
                          defaultValue={reception.wetness}
                        />
                      </div>
                    </div>
                    <p className="text-center mt-2">
                      <img
                        className="signature_img"
                        src={
                          reception.signature
                            ? reception.signature
                            : "/images/user.jpg"
                        }
                      />
                    </p>
                    <p className="text-center">Signature</p>
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <div className="reception_box">
                    <h5 className="text-center">
                      <b>Device Return Condition</b>
                    </h5>
                    <p>Funtional Testings</p>
                    <div className="row">
                      <div className="col-6">
                        {this.chooseColor(
                          "Turns On",
                          this.getColor(return_condition.turns_on)
                        )}
                        {this.chooseColor(
                          "Image",
                          this.getColor(return_condition.image)
                        )}
                        {this.chooseColor(
                          "Battery",
                          this.getColor(return_condition.battery)
                        )}
                        {this.chooseColor(
                          "Touch",
                          this.getColor(return_condition.touch)
                        )}
                        {this.chooseColor(
                          "Sound",
                          this.getColor(return_condition.sound)
                        )}
                        {this.chooseColor(
                          "Signal",
                          this.getColor(return_condition.signal)
                        )}
                      </div>
                      <div className="col-6">
                        {this.chooseColor(
                          "Vibration",
                          this.getColor(return_condition.vibration)
                        )}
                        {this.chooseColor(
                          "Front Camera",
                          this.getColor(return_condition.front_camera)
                        )}
                        {this.chooseColor(
                          "Back Camera",
                          this.getColor(return_condition.back_camera)
                        )}
                        {this.chooseColor(
                          "Bluetooth",
                          this.getColor(return_condition.bluetooth)
                        )}
                        {this.chooseColor(
                          "WiFi",
                          this.getColor(return_condition.wifi)
                        )}
                        {this.chooseColor(
                          "Face/Touch ID",
                          this.getColor(return_condition.face_id)
                        )}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-3">
                        <p>Additional Testings</p>
                      </div>
                      <div className="col-8 offset-1">
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          placeholder="Some repair"
                          defaultValue={return_condition.additional_testing}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col mb-4 ml-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckChecked"
                            readOnly
                            checked={return_condition.warranty_sticker}
                          />
                          <p
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Warranty Sticker
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-3">
                        <p>CLIENT COMMENTS</p>
                      </div>
                      <div className="col-8 offset-1">
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          placeholder="Some repair"
                          defaultValue={return_condition.client_comments}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <div className="d-flex justify-content-between bd-highlight mb-3">
                          <div className="p-2 bd-highlight">Esthetic</div>
                          <div className="p-2 bd-highlight">
                            <span className="box_text">
                              {return_condition.esthetic}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex justify-content-between bd-highlight mb-3">
                          <div className="p-2 bd-highlight">
                            General Functionality Verification{" "}
                          </div>
                          <div className="p-2 bd-highlight">
                            <span className="box_text">
                              {
                                return_condition.general_functionality_verification
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-center mt-2">
                      <img
                        className="signature_img"
                        src={
                          return_condition.signature
                            ? return_condition.signature
                            : "/images/user.jpg"
                        }
                      />
                    </p>
                    <p className="text-center">Signature</p>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    booking_detail_data: store.user.booking_detail_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    booking_details: (params) => dispatch(booking_details(params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ViewBookingDetails));
