import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const DeviceCondition = ({ warrentyreq }) => {
  function chooseColor(text, color) {
    return (
      <div className="d-flex justify-content-between bd-highlight mb-0">
        <div className="p-2 bd-highlight">{text}</div>
        <div className="p-2 bd-highlight">
          <div
            className="color_box rect"
            style={{ backgroundColor: color }}
          ></div>
        </div>
      </div>
    );
  }

  const getColor = (color) => {
    let col = parseInt(color);
    if (col === 0) {
      return "#E2EFDA";
    }
    if (col === 1) {
      return "#FFE481";
    }
    if (col === 2) {
      return "#C50004";
    }
    return "#fff";
  };
  let reception = warrentyreq?.deviceCondition;
  let returnCondition = warrentyreq?.deviceCondition;
  if(warrentyreq.bookingId){ 
    reception = warrentyreq.recieveCondition;
    returnCondition = warrentyreq.returnCondition;
  }

  return (
    <div className="my-5 ml-4">
      <GridContainer>
        <GridItem xs={7} sm={12} md={7}>
          <div className="reception_box">
            <h5 className="text-center">
              <b>Device Condition</b>
            </h5>
            <div className="row">
              <div className="col-3">
                <p>Service Validation</p>
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col">
                    <p className="text-center font-bold mb-0">Brand</p>
                    <p className="text-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        readOnly
                        checked={reception.modelType}
                      />
                      Apple
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-center font-bold mb-0">Model</p>
                    <p className="text-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        readOnly
                        checked={reception.modelName}
                      />
                      {warrentyreq.modelName}
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-center font-bold mb-0">Color</p>
                    <p className="text-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        readOnly
                        checked={reception.modelColor}
                      />
                      {warrentyreq.modelColor}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col mb-4 ml-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sameDeviceChecked"
                    readOnly
                    value="1"
                    checked={reception.same_device}
                  />
                  <p className="form-check-label" htmlFor="sameDeviceChecked">
                    <strong>Is the device same ?</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p>Previous repairs</p>
              </div>
              <div className="col-8 offset-1">
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  placeholder="Some repair"
                  defaultValue={reception.previous_repairs}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                {chooseColor("Turns On", getColor(reception.turns_on))}
                {chooseColor("Image", getColor(reception.image))}
                {chooseColor("Battery", getColor(reception.battery))}
                {chooseColor("Touch", getColor(reception.touch))}
                {chooseColor("Sound", getColor(reception.sound))}
                {chooseColor("Signal", getColor(reception.signal))}
              </div>
              <div className="col-6">
                {chooseColor("Vibration", getColor(reception.vibration))}
                {chooseColor("Front Camera", getColor(reception.front_camera))}
                {chooseColor("Back Camera", getColor(reception.back_camera))}
                {chooseColor("Bluetooth", getColor(reception.bluetooth))}
                {chooseColor("WiFi", getColor(reception.wifi))}
                {chooseColor("Face/Touch ID", getColor(reception.face_id))}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-3">
                <p>Additional Testings</p>
              </div>
              <div className="col-8 offset-1">
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  placeholder="Some repair"
                  defaultValue={returnCondition.additional_testing}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col mb-4 ml-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    readOnly
                    checked={returnCondition.warranty_sticker}
                  />
                  <p className="form-check-label" htmlFor="flexCheckChecked">
                    Warranty Sticker
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-3">
                <p>CLIENT COMMENTS</p>
              </div>
              <div className="col-8 offset-1">
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  placeholder="Some repair"
                  defaultValue={returnCondition.client_comments}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className="d-flex justify-content-between bd-highlight mb-3">
                  <div className="p-2 bd-highlight">Esthetic</div>
                  <div className="p-2 bd-highlight">
                    <span className="box_text">{returnCondition.esthetic}</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex justify-content-between bd-highlight mb-3">
                  <div className="p-2 bd-highlight">
                    General Functionality Verification{" "}
                  </div>
                  <div className="p-2 bd-highlight">
                    <span className="box_text">
                      {returnCondition.general_functionality_verification}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-3">
                <p>% Charge</p>
              </div>
              <div className="col-8 offset-1">
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  placeholder="Some repair"
                  defaultValue={reception.charge_percent}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-3">
                <p>Wetness</p>
              </div>
              <div className="col-8 offset-1">
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  placeholder="Some repair"
                  defaultValue={reception.wetness}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center gap-4 mt-3">
              <div>
                <p className="text-center mt-2">
                  <img
                    className="signature_img"
                    src={
                      warrentyreq.signature
                        ? warrentyreq.signature
                        : "/images/user.jpg"
                    }
                  />
                </p>
                <p className="text-center">Receive Signature</p>
              </div>
              <div className="ml-4">
                <p className="text-center mt-2">
                  <img
                    className="signature_img"
                    src={
                      returnCondition.signature
                        ? returnCondition.signature
                        : "/images/user.jpg"
                    }
                  />
                </p>
                <p className="text-center">Final Signature</p>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default DeviceCondition;
