import React, { Component } from "react";
import { connect } from "react-redux";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {MyCustomTable} from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import Typography from "@material-ui/core/Typography";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { bookings, searchBookingApi } from "../../actions/user";

import TextField from "@material-ui/core/TextField";
import { API_URL } from "../../config";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { BOOKING } from "../../constants/booking";

export class BookingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookings: [],
      complete_booking_data: [],
      person_id: "",
      detail_page: false,
      booking_details: [],
      page: 0,
      count: 0,
      rowsPerPage: 10,
      offset: 0,
      limit: 10,
    };
  }
  componentDidMount() {
    const {offset,limit}=this.state;
    this.props.bookings(limit,offset);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { booking_data } = newProps;
    const {offset}=this.state;
    if (booking_data && booking_data.status === 200) {
      let bookings = [];
      let count = booking_data.count;
      let complete_booking_data = booking_data.booking_history;
      let i = offset;
      booking_data.booking_history.map((data) => {
        let arr = { view: data };
        bookings.push([
          "BK000" + i,
          data.user.username,
          data.user.unique_id,
          data.technician ? data.technician.username : "Not Viable",
          data.modelName,
          data.dateTime,
          BOOKING[data.status],
          arr,
        ]);
        i++;
        return null;
      });
      this.setState({ bookings, count, complete_booking_data });
    }
  }
  backPage = () => {
    this.setState({ detail_page: false });
  };
  handleChange = (name, data) => {
    this.props.history.push("/booking/" + data.id);
  };
  handleChangePage = (event, newPage) => {
    const { rowsPerPage, limit } = this.state;
 const newOffset = newPage * rowsPerPage;
  this.setState({ page: newPage, offset: newOffset }, () => {
    this.props.bookings(limit,newOffset);
  });
  };
  handleChangeRowsPerPage = (event) => {
    const limit = parseInt(event.target.value||10);
    this.setState({ rowsPerPage: limit, limit, page: 0, offset: 0 });
  
    this.props.bookings(limit,0);
  };

  handleSearchChange = (e) => {
    if (e.target.value.length > 0) {
      this.props.searchBookingApi(e.target.value);
    } else {
      this.props.bookings();
    }
  };

  saveAsExcel = () => {
    let excel_link = API_URL + "/cms/export_booking?query=excel";

    const link = document.createElement("a");
    link.href = excel_link;

    link.setAttribute("download", "file.pdf");

    document.body.appendChild(link);
    link.click();
  };
  saveAsCsv = () => {
    let excel_link = API_URL + "/cms/export_booking?query=csv";

    const link = document.createElement("a");
    link.href = excel_link;

    link.setAttribute("download", "file.pdf");

    document.body.appendChild(link);
    link.click();
  };

  render() {
    const { bookings, detail_page, count, page, rowsPerPage } = this.state;
    return (
      <GridContainer>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <GridItem xs={12} sm={12} md={12}>
          {!detail_page && (
            <div className="parent_search_export">
              <div className="right">
                <TextField
                  id="standard-search"
                  label="Search field"
                  type="search"
                  onChange={(e) => this.handleSearchChange(e)}
                />
              </div>
              <div className="left">
                Export as:
                <img
                  src="/images/excel.svg"
                  alt="excel"
                  onClick={(e) => this.saveAsExcel(e)}
                  className="excel_icon"
                />
                <img
                  src="/images/csv.svg"
                  alt="csv"
                  onClick={(e) => this.saveAsCsv(e)}
                  className="csv_icon"
                />
              </div>
            </div>
          )}
          <Card>
            <CardHeader color="primary">
              <Typography variant="h5" className="header_platform">
                Bookings
              </Typography>
            </CardHeader>
            <CardBody>
              <MyCustomTable
                customClass="table_class_width"
                tableHeaderColor="primary"
                tableHead={[
                  "Id",
                  "User",
                  "User Id",
                  "Technician",
                  "Model",
                  "Submission Date",
                  "Status",
                  "Actions",
                ]}
                tableData={bookings}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                handleChange={this.handleChange}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    booking_data: store.user.booking_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bookings: (limit,offset) => dispatch(bookings(limit,offset)),
    searchBookingApi: (params) => dispatch(searchBookingApi(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
