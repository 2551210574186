import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PaymentIcon from "@material-ui/icons/Payment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Edit from "@material-ui/icons/Edit";
import Block from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import View from "@material-ui/icons/Visibility";

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomTable(props) {
  const classes = useStyles();

  const {
    tableHead,
    tableData,
    tableHeaderColor,
    customClass,
    rowsPerPage,
    count,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    hideEdit,
    hideView,
    tipText,
  } = props;

  return (
    <div className={customClass}>
      <Table className={classes.table}>
        {tableHead !== undefined && (
          <TableHead className={classes[`${tableHeaderColor}TableHeader`]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => (
                <TableCell
                  className={`${classes.tableCell} ${classes.tableHeadCell}`}
                  key={key}
                >
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {(rowsPerPage > 0
            ? tableData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : tableData
          ).map((prop, rowIndex) => {
            return (
              <TableRow key={rowIndex} className={classes.tableBodyRow}>
                {prop.map((cellData, cellIndex) => {
                  const isLastColumn = cellIndex === prop.length - 1;
                  const hasIconProps = cellData instanceof Object;

                  if (hasIconProps) {
                    return (
                      <TableCell className={classes.tableCell} key={cellIndex}>
                        {Object.entries(cellData).map(
                          ([iconName, iconValue]) => {
                            if (iconName === "view" && !hideView) {
                              return (
                                <span
                                  key={iconName}
                                  onClick={() =>
                                    props.handleChange(iconName, iconValue)
                                  }
                                >
                                  <Tooltip title="View">
                                    <View />
                                  </Tooltip>
                                </span>
                              );
                            } else if (iconName === "edit" && !hideEdit) {
                              return (
                                <span
                                  key={iconName}
                                  onClick={() =>
                                    props.handleChange(iconName, iconValue)
                                  }
                                >
                                  <Tooltip title="Edit">
                                    <Edit />
                                  </Tooltip>
                                </span>
                              );
                            } else if (
                              iconName === "block" ||
                              iconName === "unblock"
                            ) {
                              return (
                                <span
                                  key={iconName}
                                  onClick={() =>
                                    props.handleChange(
                                      iconName,
                                      iconValue,
                                      prop[0]
                                    )
                                  }
                                >
                                  {iconName === "block" && (
                                    <Tooltip
                                      title={tipText ? "Delete" : "Block"}
                                    >
                                      <Block />
                                    </Tooltip>
                                  )}
                                  {iconName === "unblock" && (
                                    <Tooltip title="Unblock">
                                      <CheckCircleIcon />
                                    </Tooltip>
                                  )}
                                </span>
                              );
                            } else if (iconName === "delete") {
                              return (
                                <span
                                  key={iconName}
                                  onClick={() =>
                                    props.handleChange(
                                      "delete",
                                      iconValue ? iconValue : iconValue,
                                      prop[0]
                                    )
                                  }
                                >
                                  <Tooltip title="Delete">
                                    <DeleteIcon />
                                  </Tooltip>
                                </span>
                              );
                            }

                            return null;
                          }
                        )}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell className={classes.tableCell} key={cellIndex}>
                        {cellData}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

export function MyCustomTable(props) {
  const classes = useStyles();
  const iconMap = {
    view: {
      icon: <View style={{ color: "rgba(0, 0, 0, 1)" }} />,
      tooltip: "View",
    },
    edit: {
      icon: <Edit style={{ color: "rgba(0, 0, 0, 1)" }} />,
      tooltip: "Edit",
    },
    block: {
      icon: <Block style={{ color: "rgba(0, 0, 0, 1)" }} />,
      tooltip: "Block",
    },
    unblock: {
      icon: <CheckCircleIcon style={{ color: "rgba(0, 0, 0, 1)" }} />,
      tooltip: "Unblock",
    },
    delete: {
      icon: <DeleteIcon style={{ color: "rgba(0, 0, 0, 1)" }} />,
      tooltip: "Delete",
    },
  };

  const {
    tableHead,
    tableData,
    tableHeaderColor,
    customClass,
    rowsPerPage,
    count,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    hideEdit,
    hideView,
    tipText,
  } = props;

  const startIdx = page * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;

  return (
    <div className={customClass}>
      <Table className={classes.table}>
        {tableHead && (
          <TableHead className={classes[`${tableHeaderColor}TableHeader`]}>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell className={classes.tableHeadCell} key={key}>
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {tableData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cellData, cellIndex) => {
                const isLastColumn = cellIndex === row.length - 1;
                const hasIconProps = cellData instanceof Object;

                if (hasIconProps) {
                  return (
                    <TableCell className={classes.tableCell} key={cellIndex}>
                      {Object.entries(cellData).map(([iconName, iconValue]) => {
                        const { icon, tooltip } = iconMap[iconName] || {};

                        if (
                          !icon ||
                          (iconName === "view" && hideView) ||
                          (iconName === "edit" && hideEdit)
                        ) {
                          return null;
                        }

                        return (
                          <Tooltip key={iconName} title={tooltip}>
                            <IconButton
                              onClick={() =>
                                props.handleChange(iconName, iconValue)
                              }
                            >
                              {icon}
                            </IconButton>
                          </Tooltip>
                        );
                      })}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell className={classes.tableCell} key={cellIndex}>
                      {cellData}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: 1000000 }]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ),
  customClass: PropTypes.string,
  rowsPerPage: PropTypes.number,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  hideEdit: PropTypes.bool,
  hideView: PropTypes.bool,
  tipText: PropTypes.bool,
  handleChange: PropTypes.func,
};
