import React, { Component } from "react";
import { connect } from "react-redux";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {MyCustomTable} from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import Typography from "@material-ui/core/Typography";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { orders } from "../../actions/user";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

export class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      complete_booking_data: [],
      person_id: "",
      detail_page: false,
      booking_details: [],
      page: 0,
      count: 0,
      rowsPerPage: 10,
      offset: 0,
      limit: 10,
    };
  }
  componentDidMount() {
    const {offset,limit}=this.state;
    this.props.orders(limit,offset);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { order_data } = newProps;
    const {offset}=this.state;
    if (order_data && order_data.status === true) {
      let ordersArray = [];
      let count = order_data.count;
      // let complete_booking_data = order_data?.data;
      let i = offset+1;
      order_data.data.map((data) => {
        let arr = { view: data };
        ordersArray.push([
          "" + i,
          data.user?.username ?? '',
          data.address,
          data.orderId,
          data.total,
          data.status,
          arr,
        ]);
        i++;
        return null;
      });
      this.setState({ orders: ordersArray, count: count });
    }
  }

  backPage = () => {
    this.setState({ detail_page: false });
  };
  handleChange = (name, data) => {
    this.props.history.push("/ordersDetail/" + data.id);
  };
  handleChangePage = (event, newPage) => {
    const { rowsPerPage, limit } = this.state;
 const newOffset = newPage * rowsPerPage;
  this.setState({ page: newPage, offset: newOffset }, () => {
    this.props.orders(limit,newOffset);
  });
  };
  handleChangeRowsPerPage = (event) => {
    const limit = parseInt(event.target.value||10);
    this.setState({ rowsPerPage: limit, limit, page: 0, offset: 0 });
  
    this.props.orders(limit,0);
  };

  render() {
    const { orders, detail_page, count, page, rowsPerPage } = this.state;
    return (
      <GridContainer>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Typography variant="h5" className="header_platform">
                Orders
              </Typography>
            </CardHeader>
            <CardBody>
              <MyCustomTable
                customClass="table_class_width"
                tableHeaderColor="primary"
                tableHead={[
                  "Id",
                  "User",
                  "Address",
                  "User Code",
                  "Total",
                  "Status",
                  "Actions",
                ]}
                tableData={orders}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                handleChange={this.handleChange}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    order_data: store.user.order_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orders: (limit,offset) => dispatch(orders(limit,offset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
