import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.js";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import Select from "react-select";
import "react-google-places-autocomplete/dist/index.min.css";
import GridContainer from "components/Grid/GridContainer.js";

export const API_URL = process.env.REACT_APP_URL + "/api";
export default function SendNotification(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [mSName, setMSName] = useState("");
  const [users, setUsers] = useState([]);

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    props.handleSendNotify(
      selectedOptions.map((i) => i.value),
      "selectMultipleUser"
    );
  };
  const user_type = [
    {
      value: "",
      label: "Please Select user",
    },
    {
      value: "allUser",
      label: "All User",
    },
    {
      value: "selectedUser",
      label: "Selected User",
    },
  ];

  useEffect(() => {
    axios.get(`${API_URL}/user/user`).then(({ data }) => {
      setUsers(data.users);
    });
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            onChange={(e) => props.handleSendNotify(e, "title")}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="selectUser"
            label="Select User"
            select
            type="text"
            fullWidth
            onChange={(e) => {
              setMSName(e.target.value);
              props.handleSendNotify(e, "selectUser");
            }}
          >
            {user_type.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </GridItem>
      </GridContainer>
      {mSName === "selectedUser" ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Select
              id="selectMultipleUser"
              isMulti
              name="selectMultipleUser"
              options={users?.map(
                (i) => ({ label: i.username, value: i.id } ?? [])
              )}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select User Name"
              value={selectedOptions}
              onChange={handleChange}
            />
          </GridItem>
        </GridContainer>
      ) : null}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            onChange={(e) => {
              props.handleSendNotify(e, "message");
            }}
          />
        </GridItem>
      </GridContainer>

      <DialogActions>
        <Button onClick={(e) => props.backPage(e)} variant="contained">
          Cancel
        </Button>

        <Button
          onClick={(e) => props.submitData(e, "add")}
          color="primary"
          variant="contained"
        >
          Send Notification
        </Button>
      </DialogActions>
    </div>
  );
}
