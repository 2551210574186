export const WARRANTY = {
    pending: "Evaluation requested",
    requested: "Evaluation requested",
    assigned: "Evaluation requested",
    first_approved: "Evaluation confirmed",
    first_declined: "Negative Evaluation",
    completed: "Final review",
    approved: "Positive evaluation",
    declined: "Warranty Cancelled",
    adminApproved: "Active Warranty"
}