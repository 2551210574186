/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.js";
import plus from "assets/img/plus.png";
import "./addon.css";
import "react-google-places-autocomplete/dist/index.min.css";
import GridContainer from "components/Grid/GridContainer.js";
export default function AddEditPage(props) {
  const { edit_page, person_data, role } = props.state;

  const [images, setImages] = useState([{ src: "", file: null }]);

  useEffect(() => {
    if (person_data && person_data.image) {
      let imgs = person_data.image;
      try {
        imgs = eval(imgs);
      } catch (e) {
        imgs = imgs.split(" ");
      }
      imgs = imgs.map((i) => ({ src: i, file: null }));
      setImages(imgs);
    }
  }, [person_data]);

  const handleChange = (e, index) => {
    const updatedImages = [...images];
    updatedImages[index].src = URL.createObjectURL(e.target.files[0]);
    updatedImages[index].file = e.target.files[0];
    setImages(updatedImages);
  };

  const addImage = () => {
    setImages([...images, { src: "" }]);
  };

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleSubmit = (mode) => {
    // const files = images.map((i) => i.file);
    console.log(mode);
    props.handleEditChange(images, "images");
    props.submitData(null, mode);
  };

  return (
    <div>
      <GridContainer>
        <div className="imageGroup row">
          {images.map((image, index) => (
            <div className="imageBox" key={index}>
              {image.src ? (
                <div>
                  <img alt="12" className="modal_img" src={image.src} />
                  <button
                    className="deltBtn"
                    onClick={() => deleteImage(index)}
                  >
                    X
                  </button>
                </div>
              ) : (
                <img alt="12" className="modal_img_plus" src={plus} />
              )}
              <TextField
                id={`show_image${index + 1}`}
                type="file"
                fullWidth
                onChange={(e) => {
                  handleChange(e, index);
                }}
              />
            </div>
          ))}
          {images.length !== 5 && (
            <button className="AddButton" onClick={addImage}>
              Add Image
            </button>
          )}
        </div>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={person_data?.name}
            fullWidth
            onChange={(e) => props.handleEditChange(e, "name")}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            value={person_data?.price}
            fullWidth
            onChange={(e) => props.handleEditChange(e, "price")}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <TextField
            multiline
            rows={4}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            value={person_data?.description}
            fullWidth
            onChange={(e) => props.handleEditChange(e, "description")}
          />
        </GridItem>
      </GridContainer>

      <DialogActions>
        <Button onClick={(e) => props.backPage(e)} variant="contained">
          Cancel
        </Button>
        {!edit_page ? (
          <Button
            onClick={() => handleSubmit("add")}
            color="primary"
            variant="contained"
          >
            Upload Data
          </Button>
        ) : (
          <Button
            onClick={() => handleSubmit("edit")}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </div>
  );
}
