/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import AWS from "aws-sdk";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import { awsConfigNew } from "./../../config.js";
import CircularProgress from "@material-ui/core/CircularProgress";
// import TextField from "@material-ui/core/TextField";
// import EditIcon from "@material-ui/icons/Edit";
// import {
//   personList,
//   blockPerson,
//   addPerson,
//   editPerson,
//   viewPerson,
//   searchApi,
// } from "../../actions/user";
import {
  getAllAddons,
  getAddonById,
  updateAddon,
  addOnApi,
  addAvailableAreaImage,
  deleteAddon,
} from "../../actions/device";
import { toaster } from "../../helper/Toaster";
import ViewAddOnPage from "./ViewAddOnPage";
import AddEditPage from "./AddEditPage";
import Tooltip from "@material-ui/core/Tooltip";

// import { API_URL } from "../../config";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import moment from "moment";
let detail_flag = false;
let block_flag = false;
let delete_flag = false;
let add_flag = false;
let edit_flag = false;
let s3 = new AWS.S3(awsConfigNew);

const uploadImage = (file) => {
  return new Promise((resolve, reject) => {
    var date = new Date();
    var date_create = date.getTime();
    let index = file.name.lastIndexOf(".");
    let extension = file.name.substring(index, file.name.length);
    date_create += extension;

    let params = {
      ACL: "public-read",
      ServerSideEncryption: "AES256",
      Body: file,
      Bucket: "blackpatchadmin",
      Key: `media/model/${date_create}`,
    };

    s3.putObject(params, (err, data) => {
      if (err) {
        toaster("error", err.stack);
        reject(err.message);
      } else {
        resolve(
          `https://blackpatchadmin.s3.amazonaws.com/media/model/${date_create}`
        );
      }
    });
  });
};

export class AddonPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      detail_page: false,
      add_page: false,
      edit_page: false,
      person_data: {
        name: "",
        image: "",
        imageArray: [],
        image1: "",
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        price: "",
        description: "",
        id: "",
      },
      page: 0,
      count: 0,
      rowsPerPage: 10,
    };
  }
  componentDidMount() {
    this.props.getAllAddons();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      add_on_list,
      person_data,
      add_response,
      edit_response,
      delete_response,
    } = newProps;

    // if ("add_on_list" in newProps) {
    //   let { add_on_list } = newProps;
    const data1 = [];
    let i = 1;
    if (add_on_list && add_on_list.status === 200) {
      let count = add_on_list.count;
      const prob_data = add_on_list.data;
      // let count = prob_data.length;
      prob_data.map((data) => {
        let arr = { view: data, edit: data, delete: data };
        data1.push([i++, data.name, data.price, arr]);
      });
      this.setState({
        data: data1,
        count,
      });
    }
    if (person_data && person_data.status === 200) {
      this.setState({
        person_data: person_data.data,
        // selected_person: person_data,
      });
    } else if (person_data && person_data.status === 500) {
      toaster("error", person_data.message);
    }
    if (add_response && add_response.status === 200 && add_flag) {
      toaster("success", add_response.message);
      this.setState({
        detail_page: false,
        add_page: false,
        edit_page: false,
      });
      this.props.getAllAddons();
      add_flag = false;
    } else if (add_response && add_response.status === 500 && add_flag) {
      toaster("error", add_response.message);
      add_flag = false;
    }
    if (edit_response && edit_response.status === 200 && edit_flag) {
      toaster("success", edit_response.message);
      this.setState({
        detail_page: false,
        add_page: false,
        edit_page: false,
      });
      this.props.getAllAddons();
      edit_flag = false;
    } else if (edit_response && edit_response.status === 500 && edit_flag) {
      toaster("error", edit_response.message);
      edit_flag = false;
    }
    if (delete_response && delete_response.status === 200 && delete_flag) {
      toaster("success", delete_response.message);
      this.setState({
        detail_page: false,
        add_page: false,
        edit_page: false,
      });
      this.props.getAllAddons();
      delete_flag = false;
    } else if (
      delete_response &&
      delete_response.status === 500 &&
      delete_flag
    ) {
      toaster("error", delete_response.message);
      delete_flag = false;
    }
  }
  backPage = () => {
    this.setState({
      detail_page: false,
      add_page: false,
      edit_page: false,
      person_data: {
        name: "",
        image: [],
        image1: "",
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        view_image: [],
        price: "",
        description: "",
        id: "",
        isLoading: false,
      },
    });
  };
  submitImage = (image, type) => {
    let index = image.name.lastIndexOf(".");
    let extension = image.name.substring(index, image.name.length);

    var date_create = moment().format("YYYY-MM-DD-H:m:ss.SS");
    date_create += extension;

    let params = {
      ACL: "public-read",
      ServerSideEncryption: "AES256",
      Body: image,
      Bucket: "blackpatchadmin",
      Key: `media/model/${date_create}`,
    };

    s3.putObject(params, (err, data) => {
      if (err) {
        toaster("error", err.stack);
      } else {
        let params = {
          type,
          image: `https://blackpatchadmin.s3.amazonaws.com/media/model/${date_create}`,
        };
        this.props.addAvailableAreaImage(params);
      }
    });
  };

  handleEditChange = (e, setname) => {
    var person_data = this.state.person_data;

    if (setname === "price" && /^[0-9]{0,6}$/.test(e.target.value) === false) {
      return;
    }
    if (setname === "images") {
      person_data[setname] = e;
    } else {
      person_data[setname] = e.target.value;
    }
    this.setState({ person_data });
  };

  handleChange = (setname, data) => {
    if (setname === "view") {
      this.setState({ detail_page: true });
      let params = {
        id: data.id,
      };
      this.props.getAddonById(params);
      detail_flag = true;
    } else if (setname === "delete") {
      if (window.confirm("Are you sure you want to delete this add on?")) {
        let params = {
          id: data.id,
        };
        this.props.deleteAddon(params);
        delete_flag = true;
        this.props.getAllAddons();
      }
    } else if (setname === "unblock") {
      let params = {
        id: data.id,
      };
      this.props.addOnApi(params);
      block_flag = true;
    } else if (setname === "add") {
      this.setState({
        add_page: true,
        person_data: {
          name: "",
          image: "",
          price: "",
          description: "",
        },
      });
    } else if (setname === "edit") {
      this.setState({
        edit_page: true,
        person_data: {
          name: data.name,
          image: data.image,
          price: data.price,
          description: data.description,
          id: data.id,
        },
      });
    }
  };

  submitData = async (e, setname) => {
    const { person_data } = this.state;
    const { name, price, id, description, images } = person_data;

    if ((name === "" || price === "", description === "")) {
      toaster("error", "Please select all the fields");
    } else {
      if (setname === "add") {
        this.setState({ isLoading: true });
        const imgs = [];
        for (let image of images) {
          const uploadImg = await uploadImage(image.file);
          imgs.push(uploadImg);
        }
        let params = {
          name: name,
          price: price,
          image: JSON.stringify(imgs),
          description: description,
          id: id,
        };
        add_flag = true;
        this.props.addOnApi(params);
      } else {
        const imgs = [];
        for (let image of images) {
          if (image.file) {
            const uploadImg = await uploadImage(image.file);
            imgs.push(uploadImg);
          } else {
            imgs.push(image.src);
          }
        }
        this.setState({ isLoading: true });
        let paramsEdit = {
          name: name,
          price: price,
          image: JSON.stringify(imgs),
          description: description,
          id: id,
        };

        edit_flag = true;
        await this.props.updateAddon(paramsEdit);
      }
      this.setState({ isLoading: false });
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  render() {
    const { data, detail_page, add_page, edit_page, count, page, rowsPerPage } =
      this.state;
    let title = "";
    if (detail_page) {
      title = "Details";
    } else {
      if (add_page) {
        title = "Add";
      } else {
        title = "Edit";
      }
    }

    return (
      <GridContainer>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <GridItem xs={12} sm={12} md={12}>
          {!detail_page && <div className="parent_search_export"></div>}
          <Card>
            {!detail_page && !add_page && !edit_page ? (
              <CardHeader color="primary" className="header_platform">
                <Typography variant="h5">
                  Add On
                  <Tooltip title="Add" className="icon_boundry">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={(e) => this.handleChange("add")}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </CardHeader>
            ) : (
              <CardHeader color="primary">
                <Typography variant="h5" className="header_platform">
                  {title}
                  <Tooltip title="Back" className="icon_boundry">
                    <IconButton
                      edge="end"
                      aria-label="back"
                      onClick={(e) => this.backPage(e)}
                    >
                      <SubdirectoryArrowLeftIcon />
                    </IconButton>
                  </Tooltip>
               {/*    {(edit_page) && <Tooltip title="Edit" className='icon_boundry'>
                     <IconButton
                       edge="end"
                       aria-label="back"
                       onClick={e => this.handleChange("edit")}
                     >
                       <EditIcon />
                     </IconButton>
                   </Tooltip>} */}
                </Typography>
              </CardHeader>
            )}

            {this.state.isLoading === true ? (
              <CircularProgress className="loader_class" />
            ) : (
              <CardBody>
                {!detail_page && !add_page && !edit_page && (
                  <Table
                    customClass="table_class_width"
                    tableHeaderColor="primary"
                    tableHead={["S.No.", "Name", "Price", "Actions"]}
                    tableData={data}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={count}
                    handleChange={this.handleChange}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
                {detail_page && (!add_page || !edit_page) && (
                  <ViewAddOnPage
                    state={this.state}
                    handleEditChange={this.handleEditChange}
                    backPage={this.backPage}
                  />
                )}
                {!detail_page && (add_page || edit_page) && (
                  <AddEditPage
                    state={this.state}
                    handleEditChange={this.handleEditChange}
                    backPage={this.backPage}
                    submitData={this.submitData}
                  />
                )}
              </CardBody>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    add_on_list: store.device.add_on_list,
    // data: store.user.data,
    // block_response: store.user.block_response,
    person_data: store.device.person_data,
    add_response: store.device.add_response,
    edit_response: store.device.edit_response,
    delete_response: store.device.delete_response,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAddons: () => dispatch(getAllAddons()),
    addAvailableAreaImage: (params) => dispatch(addAvailableAreaImage(params)),
    getAddonById: (params) => dispatch(getAddonById(params)),
    updateAddon: (params) => dispatch(updateAddon(params)),
    addOnApi: (params) => dispatch(addOnApi(params)),
    deleteAddon: (params) => dispatch(deleteAddon(params)),
    // searchApi: (params) => dispatch(searchApi(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonPage);
