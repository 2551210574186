import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { toaster } from "../../helper/Toaster";
import { CircularProgress } from "@material-ui/core";
import { MOBILE_API_URL } from "../../config";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReactivateRequestPopup({ isOpen, close, bookingId, onSuccess }) {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    close();
  };

  const handleClick = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${MOBILE_API_URL}/booking/reactivate-booking/${bookingId}`
      );
      if (!data.status) {
        toaster("error", data.message);
        setLoading(false);
        close();
        return;
      }
      toaster(
        "success",
        "This booking is reactivated! Please wait until technicians accept the request!"
      );
      onSuccess();
    } catch (e) {
      toaster("error", e.message);
    } finally {
      setLoading(false);
      close();
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {loading ? (
        <div style={{ margin: "50px" }}>
          <CircularProgress className="loader_class" />
        </div>
      ) : (
        <>
          <DialogTitle id="alert-dialog-slide-title">
            Are you sure you want to reactivate this request ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClick} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
