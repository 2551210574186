import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import withTheme from "@material-ui/styles/withTheme";

import { orders_details } from "../../actions/user";
import { connect } from "react-redux";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Typography from "@material-ui/core/Typography";
import CardBody from "components/Card/CardBody.js";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { Button } from "@material-ui/core";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

import { API_URL } from "../../config";
import { toaster } from "../../helper/Toaster";

class ViewOrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        deliveryCharges: "",
        address: "",
        final_total: "",
        orderId: "",
        discount: "",
        total: "",
        status: "",
        user: { username: "", createdAt: "" },
        items: [],
      },
    };
  }
  componentDidMount() {
    this.props.orders_details({ id: this.props.match.params.id });
  }

  getColor = (color) => {
    let col = parseInt(color);
    if (col === 0) {
      return "#E2EFDA";
    }
    if (col === 1) {
      return "#FFE481";
    }
    if (col === 2) {
      return "#C50004";
    }
    return "#fff";
  };

  backPage = () => {
    this.props.history.push("/Orders");
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const { order_detail_data } = newProps;
    if (order_detail_data && order_detail_data.status === true) {
      let order_detail = order_detail_data.data;
      this.setState({ booking: order_detail });
    }
  }
  deliverOrder = async () => {
    const order = this.state.booking;
    await axios.get(`${API_URL}/cart/order/${order.id}/deliver`);
    toaster("success", "Order delivered successfully!")
    this.props.orders_details({ id: this.props.match.params.id });
  };

  chooseColor(text, color) {
    return (
      <div className="d-flex justify-content-between bd-highlight mb-0">
        <div className="p-2 bd-highlight">{text}</div>
        <div className="p-2 bd-highlight">
          <div
            className="color_box rect"
            style={{ backgroundColor: color }}
          ></div>
        </div>
      </div>
    );
  }

  render() {
    let { booking } = this.state;

    const getImage = (item) => {
      let imgString = item.addOn.image;
      try {
        const images = JSON.parse(imgString);
        return images[0];
      } catch (e) {}
    };

    return (
      <div>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <Card>
          <CardHeader color="primary">
            <Typography variant="h5" className="header_platform">
              Order Details
              <Tooltip title="Back" className="icon_boundry">
                <IconButton
                  edge="end"
                  aria-label="back"
                  onClick={(e) => this.backPage()}
                >
                  <SubdirectoryArrowLeftIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="User"
                  type="text"
                  value={booking.user?.username ?? ''}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Address"
                  type="text"
                  value={booking.address}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Final Total"
                  type="text"
                  value={booking.final_total}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="User Code"
                  type="text"
                  value={booking.orderId}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="	Total"
                  type="text"
                  value={booking.total}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="	Delivery Charges"
                  type="text"
                  value={booking.deliveryCharges}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Order time"
                  type="text"
                  value={moment(booking.createdAt).format("LLL")}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {booking.status === "completed" ? (
                  <div className="m-3">
                    <Button
                      onClick={this.deliverOrder}
                      color="primary"
                      variant="contained"
                    >
                      Mark as delivered
                    </Button>
                  </div>
                ) : (
                  <TextField
                    margin="dense"
                    id="name"
                    label="Status"
                    type="text"
                    value={booking.status}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h3>Order Items</h3>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <List>
                  {booking &&
                    booking.items?.map((i) => (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={getImage(i)} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={i.addOn.name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {`$ ${i.price} x ${i.product_quantity}`}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            $ {+i.price * +i.product_quantity}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </>
                    ))}
                </List>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    order_detail_data: store.user.order_detail_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orders_details: (params) => dispatch(orders_details(params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ViewOrderDetails));
