import React, { Component } from "react";
import { connect } from "react-redux";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import Typography from "@material-ui/core/Typography";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { warranty_request_list } from "../../actions/user";
import TextField from "@material-ui/core/TextField";
import { API_URL } from "../../config";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { WARRANTY } from "../../constants/warranty";

export class WarrantyRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      complete_booking_data: [],
      person_id: "",
      detail_page: false,
      booking_details: [],
      page: 0,
      count: 0,
      rowsPerPage: 10,
    };
  }
  componentDidMount() {
    this.props.warranty_request_list();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { warranty_request_list_data } = newProps;

    if (
      warranty_request_list_data &&
      warranty_request_list_data.status === true
    ) {
      let ordersArray = [];
      let count = warranty_request_list_data.data.length;
      // let complete_booking_data = order_data?.data;
      let i = 1;
      warranty_request_list_data.data.map((data) => {
        let arr = { view: data };
        ordersArray.push([
          "" + i,
          data.user.username,
          data.technician.username,
          data.user.unique_id,
          data.modelName,
          data.price,
          WARRANTY[data.status],
          data.price,
          arr,
        ]);
        i++;
        return null;
      });
      this.setState({ orders: ordersArray, count: count });
    }
  }

  backPage = () => {
    this.setState({ detail_page: false });
  };
  handleChange = (name, data) => {
    this.props.history.push("/warrantyRequestDetail/" + data.id);
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  // handleSearchChange = (e) => {
  //   if (e.target.value.length > 0) {
  //     this.props.searchBookingApi(e.target.value);
  //   } else {
  //     this.props.orders();
  //   }
  // };

  render() {
    const { orders, detail_page, count, page, rowsPerPage } = this.state;
    return (
      <GridContainer>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <GridItem xs={12} sm={12} md={12}>
          
          <Card>
            <CardHeader color="primary">
              <Typography variant="h5" className="header_platform">
                Warranty Request
              </Typography>
            </CardHeader>
            <CardBody>
              <Table
                customClass="table_class_width"
                tableHeaderColor="primary"
                tableHead={["Id", "User", "Technician", "Usercode", "Model Name", "Price", "Status", "Price", "Actions"]}
                tableData={orders}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                handleChange={this.handleChange}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    warranty_request_list_data: store.user.warranty_request_list_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    warranty_request_list: (params) => dispatch(warranty_request_list(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyRequest);
