import React from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import withTheme from "@material-ui/styles/withTheme";
import { toaster } from "../../helper/Toaster";

import { warranty_request_details } from "../../actions/user";
import { connect } from "react-redux";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Typography from "@material-ui/core/Typography";
import CardBody from "components/Card/CardBody.js";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { Button, Link } from "@material-ui/core";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { API_URL, IMG_PATH } from "../../config";
import { WARRANTY } from "../../constants/warranty";
import DeviceCondition from "./DeviceCondition";
import CircularProgress from "@material-ui/core/CircularProgress";

class ViewWarrantyRequestDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      warrentyreq: {
        user: { username: "", unique_id: "" },
        technician: { username: "" },
        address: "",
        warrentyreqId: "",
        createdAt: "",
        deductible_payment_event_1: "",
        deductible_payment_event_2: "",
        id: "",
        imei: "",
        isOngoing: "",
        lat: "",
        lng: "",
        modelColor: "",
        modelId: "",
        modelName: "",
        name: "",
        price: "",
        repliedAt: "",
        technicianId: "",
        status: "",
        updatedAt: "",
        userId: "",
        warrantyId: "",
        zip: "",
        arr: "",
        status: "",
        adminApprovedAt: "",
      },
      loading: true,
    };
  }
  componentDidMount() {
    this.props.warranty_request_details({ id: this.props.match.params.id });
  }

  backPage = () => {
    this.props.history.push("/warrantyRequest");
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    const { warranty_request_details_data } = newProps;
    if (
      warranty_request_details_data &&
      warranty_request_details_data.status === true
    ) {
      let wr_detail = warranty_request_details_data.data;
      this.setState({ warrentyreq: wr_detail, loading: false });
    }
  }

  getExpireDate() {
    const requestStarted = this.state.warrentyreq.adminApprovedAt;
    const after100Days = moment(requestStarted).add(200, "days").format("LLL");
    return after100Days;
  }

  chooseColor(text, color) {
    return (
      <div className="d-flex justify-content-between bd-highlight mb-0">
        <div className="p-2 bd-highlight">{text}</div>
        <div className="p-2 bd-highlight">
          <div
            className="color_box rect"
            style={{ backgroundColor: color }}
          ></div>
        </div>
      </div>
    );
  }

  showWarranty = () => {
    const request = this.state.warrentyreq;
    window.open(`${IMG_PATH}warranty-${request.warrantyId}.pdf`);
  };

  showWarrantyEs = () => {
    const request = this.state.warrentyreq;
    window.open(`${IMG_PATH}warranty-${request.warrantyId}-es.pdf`);
  };

  viableRequest = async () => {
    const request = this.state.warrentyreq;
    await axios.get(`${API_URL}/user/warrantyRequest/${request.id}/approve`);
    toaster("success", "Request approved successfully!");
    this.props.warranty_request_details({ id: this.props.match.params.id });
  };

  render() {
    let { warrentyreq, loading } = this.state;
    return (
      <div>
        {!loading ? (
          <div>
            <ToastsContainer
              store={ToastsStore}
              position={ToastsContainerPosition.TOP_RIGHT}
            />
            <Card>
              <CardHeader color="primary">
                <Typography variant="h5" className="header_platform">
                  Warranty Details
                  <Tooltip title="Back" className="icon_boundry">
                    <IconButton
                      edge="end"
                      aria-label="back"
                      onClick={(e) => this.backPage()}
                    >
                      <SubdirectoryArrowLeftIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="User name"
                      type="text"
                      value={warrentyreq.user.username}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Technician name"
                      type="text"
                      value={warrentyreq.technician.username}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Model Name"
                      type="text"
                      value={warrentyreq.modelName}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Model Color"
                      type="text"
                      value={warrentyreq.modelColor}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Address"
                      type="text"
                      value={warrentyreq.address}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Created"
                      type="text"
                      value={moment(warrentyreq.createdAt).format("LLL")}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Price"
                      type="text"
                      value={warrentyreq.price}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Deductible Payment Event 1"
                      type="text"
                      value={warrentyreq.deductible_payment_event_1}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Deductible Payment Event 2"
                      type="text"
                      value={warrentyreq.deductible_payment_event_2}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    {warrentyreq.status === "approved" ? (
                      <div className="m-3">
                        <Button
                          onClick={this.viableRequest}
                          color="primary"
                          variant="contained"
                        >
                          Approve request
                        </Button>
                      </div>
                    ) : (
                      <TextField
                        margin="dense"
                        id="name"
                        label="Status"
                        type="text"
                        value={WARRANTY[warrentyreq.status] ?? ""}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <h3>User Info</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="Name"
                      type="text"
                      value={warrentyreq.name}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="IMEI"
                      type="text"
                      value={warrentyreq.imei}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      margin="dense"
                      id="name"
                      label="User Code"
                      type="text"
                      value={warrentyreq.user.unique_id}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  {warrentyreq.status === "adminApproved" && (
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        margin="dense"
                        id="name"
                        label="Expire date"
                        type="text"
                        value={this.getExpireDate()}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </GridItem>
                  )}
                  {warrentyreq.status === "adminApproved" && (
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <div style={{ margin: "20px 0px" }}>
                          <Button
                            onClick={this.showWarranty}
                            color="primary"
                            variant="contained"
                          >
                            View Warranty PDF
                          </Button>
                        </div>
                        <div style={{ margin: "20px 0px" }}>
                          <Button
                            onClick={this.showWarrantyEs}
                            color="primary"
                            variant="contained"
                          >
                            View Warranty PDF (ES)
                          </Button>
                        </div>
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
                {["accepted", "declined", "adminApproved"].includes(
                  warrentyreq.status
                ) && <DeviceCondition warrentyreq={warrentyreq} />}
              </CardBody>
            </Card>
          </div>
        ) : (
          <CircularProgress className="loader_class" />
        )}
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    warranty_request_details_data: store.user.warranty_request_details_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    warranty_request_details: (params) =>
      dispatch(warranty_request_details(params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ViewWarrantyRequestDetails));
