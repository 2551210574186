export default function device(
  state = {
    person_data: {},
    warranty_data: {},
    platform_list: {},
    brand_list: {},
    issue_list: {},
    model_list: {},
    edit_response: {},
    delete_response: {},
    add_response: {},
    get_model: {},
    add_on_list: [],
    warranty_level_list: [],
    search_list: [],
    search_list_ongoing: [],
  },
  action
) {
  switch (action.type) {
    case "PLATFORM_LIST_PENDING":
      return { ...state, platform_list: {} };
    case "PLATFORM_LIST_FULFILLED":
      return { ...state, platform_list: action.payload.data };
    case "PLATFORM_LIST_REJECTED":
      return { ...state, platform_list: {} };

    case "BRAND_LIST_PENDING":
      return { ...state, brand_list: {} };
    case "BRAND_LIST_FULFILLED":
      return { ...state, brand_list: action.payload.data };
    case "BRAND_LIST_REJECTED":
      return { ...state, brand_list: {} };

    case "ISSUE_LIST_PENDING":
      return { ...state, issue_list: {} };
    case "ISSUE_LIST_FULFILLED":
      return { ...state, issue_list: action.payload.data };
    case "ISSUE_LIST_REJECTED":
      return { ...state, issue_list: {} };

    case "MODEL_LIST_PENDING":
      return { ...state, model_list: {} };
    case "MODEL_LIST_FULFILLED":
      return { ...state, model_list: action.payload.data };
    case "MODEL_LIST_REJECTED":
      return { ...state, model_list: {} };

    case "ADD_PLATFORM_PENDING":
      return { ...state, add_response: {} };
    case "ADD_PLATFORM_FULFILLED":
      return { ...state, add_response: action.payload.data };
    case "ADD_PLATFORM_REJECTED":
      return { ...state, add_response: {} };

    case "EDIT_PLATFORM_PENDING":
      return { ...state, edit_response: {} };
    case "EDIT_PLATFORM_FULFILLED":
      return { ...state, edit_response: action.payload.data };
    case "EDIT_PLATFORM_REJECTED":
      return { ...state, edit_response: {} };

    case "DELETE_PLATFORM_PENDING":
      return { ...state, delete_response: {} };
    case "DELETE_PLATFORM_FULFILLED":
      return { ...state, delete_response: action.payload.data };
    case "DELETE_PLATFORM_REJECTED":
      return { ...state, delete_response: {} };

    case "ADD_BRAND_PENDING":
      return { ...state, add_response: {} };
    case "ADD_BRAND_FULFILLED":
      return { ...state, add_response: action.payload.data };
    case "ADD_BRAND_REJECTED":
      return { ...state, add_response: {} };

    case "EDIT_BRAND_PENDING":
      return { ...state, edit_response: {} };
    case "EDIT_BRAND_FULFILLED":
      return { ...state, edit_response: action.payload.data };
    case "EDIT_BRAND_REJECTED":
      return { ...state, edit_response: {} };

    case "ADD_ISSUE_PENDING":
      return { ...state, add_response: {} };
    case "ADD_ISSUE_FULFILLED":
      return { ...state, add_response: action.payload.data };
    case "ADD_ISSUE_REJECTED":
      return { ...state, add_response: {} };

    case "EDIT_ISSUE_PENDING":
      return { ...state, edit_response: {} };
    case "EDIT_ISSUE_FULFILLED":
      return { ...state, edit_response: action.payload.data };
    case "EDIT_ISSUE_REJECTED":
      return { ...state, edit_response: {} };

    case "DELETE_ISSUE_PENDING":
      return { ...state, delete_response: {} };
    case "DELETE_ISSUE_FULFILLED":
      return { ...state, delete_response: action.payload.data };
    case "DELETE_ISSUE_REJECTED":
      return { ...state, delete_response: {} };

    case "DELETE_BRAND_PENDING":
      return { ...state, delete_response: {} };
    case "DELETE_BRAND_FULFILLED":
      return { ...state, delete_response: action.payload.data };
    case "DELETE_BRAND_REJECTED":
      return { ...state, delete_response: {} };

    case "GET_MODEL_PENDING":
      return { ...state, get_model: {} };
    case "GET_MODEL_FULFILLED":
      return { ...state, get_model: action.payload.data };
    case "GET_MODEL_REJECTED":
      return { ...state, get_model: {} };

    case "ADD_MODEL_PENDING":
      return { ...state, add_response: {} };
    case "ADD_MODEL_FULFILLED":
      return { ...state, add_response: action.payload.data };
    case "ADD_MODEL_REJECTED":
      return { ...state, add_response: {} };

    case "EDIT_MODEL_PENDING":
      return { ...state, edit_response: {} };
    case "EDIT_MODEL_FULFILLED":
      return { ...state, edit_response: action.payload.data };
    case "EDIT_MODEL_REJECTED":
      return { ...state, edit_response: {} };

    case "DELETE_MODEL_PENDING":
      return { ...state, delete_response: {} };
    case "DELETE_MODEL_FULFILLED":
      return { ...state, delete_response: action.payload.data };
    case "DELETE_MODEL_REJECTED":
      return { ...state, delete_response: {} };

    case "GET_ALL_ADDON_PENDING":
      return { ...state, add_on_list: {} };
    case "GET_ALL_ADDON_FULFILLED":
      return { ...state, add_on_list: action.payload.data };
    case "GET_ALL_ADDON_REJECTED":
      return { ...state, add_on_list: {} };

    case "GET_ADDON_BY_ID_PENDING":
      return { ...state, person_data: {} };
    case "GET_ADDON_BY_ID_FULFILLED":
      return { ...state, person_data: action.payload.data };
    case "GET_ADDON_BY_ID_REJECTED":
      return { ...state, person_data: {} };

    case "GET_NOTIFY_BY_ID_PENDING":
      return { ...state, notification_data: {} };
    case "GET_NOTIFY_BY_ID_FULFILLED":
      return { ...state, notification_data: action.payload.data };
    case "GET_NOTIFY_BY_ID_REJECTED":
      return { ...state, notification_data: {} };

    case "DELETE_ADDON_PENDING":
      return { ...state, delete_response: {} };
    case "DELETE_ADDON_FULFILLED":
      return { ...state, delete_response: action.payload.data };
    case "DELETE_ADDON_REJECTED":
      return { ...state, delete_response: {} };

    case "UPDATE_ADDONS_PENDING":
      return { ...state, edit_response: {} };
    case "UPDATE_ADDONS_FULFILLED":
      return { ...state, edit_response: action.payload.data };
    case "UPDATE_ADDONS_REJECTED":
      return { ...state, edit_response: {} };

    case "ADD_ON_PENDING":
      return { ...state, add_response: {} };
    case "ADD_ON_FULFILLED":
      return { ...state, add_response: action.payload.data };
    case "ADD_ON_REJECTED":
      return { ...state, add_response: {} };

    case "GET_ALL_WARRANTY_LEVEL_PENDING":
      return { ...state, warranty_level_list: {} };
    case "GET_ALL_WARRANTY_LEVEL_FULFILLED":
      return { ...state, warranty_level_list: action.payload.data };
    case "GET_ALL_WARRANTY_LEVEL_REJECTED":
      return { ...state, warranty_level_list: {} };

    case "GET_WARRANTY_LEVEL_BY_ID_PENDING":
      return { ...state, warranty_data: {} };
    case "GET_WARRANTY_LEVEL_BY_ID_FULFILLED":
      return { ...state, warranty_data: action.payload.data };
    case "GET_WARRANTY_LEVEL_BY_ID_REJECTED":
      return { ...state, warranty_data: {} };

    case "ADD_WARRANTY_LEVEL_PENDING":
      return { ...state, add_response: {} };
    case "ADD_WARRANTY_LEVEL_FULFILLED":
      return { ...state, add_response: action.payload.data };
    case "ADD_WARRANTY_LEVEL_REJECTED":
      return { ...state, add_response: {} };

    case "UPDATE_WARRANTY_LEVEL_PENDING":
      return { ...state, edit_response: {} };
    case "UPDATE_WARRANTY_LEVEL_FULFILLED":
      return { ...state, edit_response: action.payload.data };
    case "UPDATE_WARRANTY_LEVEL_REJECTED":
      return { ...state, edit_response: {} };

    case "DELETE_WARRANTY_LEVEL_PENDING":
      return { ...state, delete_response: {} };
    case "DELETE_WARRANTY_LEVEL_FULFILLED":
      return { ...state, delete_response: action.payload.data };
    case "DELETE_WARRANTY_LEVEL_REJECTED":
      return { ...state, delete_response: {} };

    case "SEARCH_WARRANTY_LEVEL_PENDING":
      return { ...state, search_list: {} };
    case "SEARCH_WARRANTY_LEVEL_FULFILLED":
      return { ...state, search_list: action.payload.data };
    case "SEARCH_WARRANTY_LEVEL_REJECTED":
      return { ...state, search_list: {} };
    case "SEARCH_WARRANTY_LEVEL_ONGOING_PENDING":
      return { ...state, search_list_ongoing: {} };
    case "SEARCH_WARRANTY_LEVEL_ONGOING_FULFILLED":
      return { ...state, search_list_ongoing: action.payload.data };
    case "SEARCH_WARRANTY_LEVEL_ONGOING_REJECTED":
      return { ...state, search_list_ongoing: {} };
    default:
  }

  return state;
}
