import React from "react";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.js";
import MenuItem from "@material-ui/core/MenuItem";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// If you want to use the provided css
import "react-google-places-autocomplete/dist/index.min.css";
import GridContainer from "components/Grid/GridContainer.js";

export default function AddEditPage(props) {
  const { edit_page, warranty_data, role } = props.state;
  const gender_data = [
    {
      value: "",
      label: "Please Select A Gender",
    },
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
    {
      value: "O",
      label: "Other",
    },
  ];
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="levels"
            label="Levels"
            type="text"
            value={warranty_data?.levels}
            fullWidth
            onChange={(e) => props.handleEditChange(e, "levels")}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            value={warranty_data?.price}
            fullWidth
            onChange={(e) => props.handleEditChange(e, "price")}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="deductible_payment_event_1"
            label="Deductible payment event 1 (%)"
            type="number"
            value={warranty_data?.deductible_payment_event_1}
            fullWidth
            onChange={(e) =>
              props.handleEditChange(e, "deductible_payment_event_1")
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="deductible_payment_event_2"
            label="Deductible payment event 2 (%)"
            type="number"
            value={warranty_data?.deductible_payment_event_2}
            fullWidth
            onChange={(e) =>
              props.handleEditChange(e, "deductible_payment_event_2")
            }
          />
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="isOngoing"
            label="Ongoing"
            type="text"
            value={warranty_data?.isOngoing}
            fullWidth
            onChange={(e) => props.handleEditChange(e, "isOngoing")}
          />
        </GridItem> */}
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            select
            margin="dense"
            id="isOngoing"
            label="Ongoing"
            type="text"
            value={warranty_data?.isOngoing}
            defaultValue={warranty_data?.isOngoing}
            fullWidth
            onChange={(e) => props.handleEditChange(e, "isOngoing")}
          >
            {/* <MenuItem value={warranty_data?.isOngoing}></MenuItem> */}
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </TextField>
        </GridItem>
      </GridContainer>

      <DialogActions>
        <Button onClick={(e) => props.backPage(e)} variant="contained">
          Cancel
        </Button>
        {edit_page ? (
          <Button
            onClick={(e) => props.submitData(e, "edit")}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        ) : (
          <Button
            onClick={(e) => props.submitData(e, "add")}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </div>
  );
}
