export const API_URL = process.env.REACT_APP_URL + "/api";
export const MOBILE_API_URL = process.env.REACT_APP_URL + "/mobile/api";
export const IMG_PATH = process.env.REACT_APP_URL + "/";

export const cryptr_key =
  "$%^&*###$$T$%%^$T$%ecece22121cecc12c12ec###-black-patch-app-**--**098789089898989";
const token = localStorage.getItem("jorge_token");
let axios_config = {};
if (token && token !== undefined && token !== null && token !== "null") {
  axios_config = {
    headers: {
      Authorization: "Token" + token
    }
  };
}
export const axiosConfig = axios_config;

export const awsConfig = {
  bucketName: 'blackpatchadmin',
  dirName: 'media', /* optional */
  region: 'us-east-1',
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  s3Url: 'https://blackpatchadmin.s3.amazonaws.com/', /* optional */
}

export const awsConfigNew = {
  bucketName: "blackpatchapp",
  dirName: "media" /* optional */,
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  s3Url: "https://blackpatchadmin.s3.amazonaws.com/" /* optional */
};
