/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import {
    ToastsContainer,
    ToastsStore,
    ToastsContainerPosition
  } from "react-toasts";
import GridItem from "components/Grid/GridItem.js";

 

class TechSupport extends Component {
  constructor(props) {
      super(props);
  }

  componentDidMount(){
  }

  goTo = (type) => {
    this.props.history.push("/chat/"+type)
  }

    render(){
        return (
          <div>
          <GridContainer>
              
            <ToastsContainer
              store={ToastsStore}
              position={ToastsContainerPosition.TOP_RIGHT}
            />
            <GridItem xs={12} sm={12} md={12}>
                <h4>Select the department </h4>
            </GridItem>
          </GridContainer>
          <div className="margin-top">
          <GridContainer spacing={1}>
            <GridItem xs={6} sm={6} md={6}>
              <div className="for_center">
                <button className="button_opt" onClick={() => this.goTo('client')}>
                  Client Operations
                </button>
              </div>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
            <div className="">
              <button className="button_opt" onClick={() => this.goTo('payment')}>
                Payment Operations
              </button>
              </div>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <div className="for_center margin-top">
                <button className="button_opt" onClick={() => this.goTo('service')}>
                  Service Operations
                </button>
              </div>
            </GridItem>


          </GridContainer>
          <GridContainer spacing={1}>
            
          </GridContainer>
          </div>
          </div>
        )
    }
}


export default TechSupport;